






import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import LatestUpdateTable from "./LatestUpdateTable.vue";

@Component({ components: { LatestUpdateTable } })
export default class LatestUpdate extends Vue {}
