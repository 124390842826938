








































































































































































import { BannerStatus, LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import AuthService from "@/service/authService";
import latestUpdateActionList from "@/views/latestUpdate/LatestUpdateActionList.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LatestUpdateTableViewModel } from "../../model/latestUpdate/latestUpdateModel";

const StoreMerchant = namespace("Merchant");
const StoreLatestUpdate = namespace("LatestUpdate");

const auth = new AuthService();
@Component({ components: { latestUpdateActionList } })
export default class LatestUpdateTable extends Vue {
  constructor() {
    super();
    this.getBannerPermission();
  }
  private textSearch = "";
  private codeSearch = "";
  private bannerStatus = BannerStatus.None;
  private BannerStatus = BannerStatus;
  private bannerStatusList = [BannerStatus.Pending, BannerStatus.Live, BannerStatus.Expired];
  private bannerStatusText = ["None", "Pending", "Live", "Expired"];
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "Order", value: "order" },
      { text: "Code", value: "code" },
      { text: "Name", value: "name" },
      { text: "Start Date", value: "startDatetime" },
      { text: "End Date", value: "endDatetime" },
      { text: "Create Date", value: "createDate" },
      { text: "Create User", value: "createUser" },
      { text: "Status", value: "status" },
      { text: "Audience", value: "audience" },
      { text: "Action", value: "action" },
    ],
    detail: [],
  };
  private datefrom = "";
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = "";
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];

  @StoreLatestUpdate.Action
  private DoGetBannerTable!: (latestUpdateTableViewModel: LatestUpdateTableViewModel) => void;
  @StoreLatestUpdate.Getter
  private getBannerTableLoadingStatus!: LoadingStatusType;
  @StoreLatestUpdate.Getter
  private getBannerTableListModels!: Array<any> | null;
  @StoreLatestUpdate.Getter
  private getCountItemTable!: number;
  private createPermission = false;
  private sortPermission = false;
  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetBannerTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetBannerTable(1, this.pageSize);
  }
  @Watch("getBannerTableLoadingStatus", { immediate: true })
  getBannerTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getBannerTableListModels && this.getBannerTableListModels.length > 0) {
        this.getBannerTableListModels.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.table.detail.sort((a: any, b: any) => a.order - b.order);
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }
  created() {
    this.GetBannerTable(this.pageNo, this.pageSize);
  }
  public FilteredHeaders() {
    const filterHeader = this.table.header.filter((header: any) => {
      if (header.value === "order") {
        return this.bannerStatusList[this.bannerStatus] !== BannerStatus.Expired;
      }
      if (header.value === "action") {
        return this.bannerStatusList[this.bannerStatus] === BannerStatus.Pending;
      }
      return true;
    });
    return filterHeader;
  }
  public GetBannerTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    const latestUpdateTableViewModel: LatestUpdateTableViewModel = {
      pageSize: pageSize,
      pageNo: pageNo,
      fromDate: this.datefrom,
      toDate: this.dateto,
      name: this.textSearch,
      code: this.codeSearch,
      bannerTabStatus: this.bannerStatusList[this.bannerStatus],
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.DoGetBannerTable(latestUpdateTableViewModel);
  }
  public SearchBannerTable() {
    this.FilteredHeaders();
    this.GetBannerTable(this.pageNo, this.pageSize);
  }
  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public RedirectToCreateLatestUpdate() {
    window.location.href = "/createLatestUpdate";
  }
  public RedirectToSortOrderLatestUpdate() {
    window.location.href = "/sortOrderLatestUpdate";
  }
  public SearchBanner() {
    this.GetBannerTable(1, 10);
    this.pageNo = 1;
    this.pageSize = 10;
  }
  public ViewBannerDetail(code: string) {
    const routeData = this.$router.resolve({
      name: "detailLatestUpdate",
      query: { code: code },
    });
    window.open(routeData.href, "_blank");
  }
  public async getBannerPermission() {
    const accessPermission = await auth.getPermissions();
    this.createPermission = accessPermission["banner"]["create"];
    this.sortPermission = accessPermission["banner"]["sort"];
  }
}
