





























import ModalDeleteLatestUpdate from "@/components/modal/latestUpdate/ModalDeleteLatestUpdate.vue";
import { BannerActionMenu } from "@/enums/enums";
import AuthService from "@/service/authService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = new AuthService();

@Component({
  components: {
    ModalDeleteLatestUpdate,
  },
})
export default class LatestUpdateActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;
  private isShowDeleteLatestUpdateModal = false;
  private merchantDropdownModelSelect: any = null;
  private items = [{ title: "Edit" }, { title: "Delete" }];

  private bannerAction: any = [];
  private showList = false;

  constructor() {
    super();
    this.initializeMenuList();
  }

  public async initializeMenuList(): Promise<void> {
    const bannerPermissions = await this.getBannerPermissions();
    this.bannerAction = [
      {
        text: "Edit",
        actionValue: BannerActionMenu.Edit,
        permission: bannerPermissions.edit,
      },
      {
        text: "Delete",
        actionValue: BannerActionMenu.Delete,
        permission: bannerPermissions.delete,
      },
    ];
  }
  public async getBannerPermissions() {
    const accessPermission = await auth.getPermissions();
    const permission = accessPermission["banner"];
    return permission;
  }
  handleItemClick(actionItem: any) {
    const { actionValue } = actionItem;
    switch (actionValue) {
      case BannerActionMenu.Edit:
        this.EditBanner();
        break;
      case BannerActionMenu.Delete:
        this.DeleteBanner();
        break;
    }
  }
  public EditBanner() {
    const routeData = this.$router.resolve({
      name: "editLatestUpdate",
      query: { code: this.item.code },
    });
    window.open(routeData.href, "_blank");
  }
  public DeleteBanner() {
    this.isShowDeleteLatestUpdateModal = true;
  }
  public ShowActionList(newValue: boolean) {
    this.showList = newValue;
  }
}
